body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  counter-reset: page;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media print {
  .noprint {
    display: none !important
  }

  * {
    font-size: 12px;
  }

  td,
  th {
    border: 1px solid black !important;
  }
}

@media screen {
  .onlyprint {
    display: none !important
  }

  tbody tr:nth-of-type(odd) {
    background-color: #9e9e9e10
  }
}